Vue.component('favoriteBtn', {
    props:['favorite'],
    template:` <p> <i :class="{fas:true ,'fa-minus'=favorite,'fa-plus':!favorite}"> </i></p>`
})

Vue.component('itemViewModuleComponent',{
    props:['item',"stock"],
    data:()=>{
        return {
            itemInCar:0,
            Qty:1,
            favorite:false
        }
    },
    mounted: function () {
        this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code)!=-1;
    },
    watch: {
        item: function () {
            this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code)!=-1
            this.$store.dispatch('updateCarAmount');
        },
        currentItemPrice:function(){
            this.$store.dispatch('updateCarAmount');
        }
    },
    computed:{
        storeItem(){
            return this.$store.getters.getItemByCode(this.item.Code);
        },
        currentItemPrice(){
            let test = this.$store.state.itemTimeFlag;
            let test2 = this.$store.state.itemUpdate;
            return this.$store.getters.showItemPrice(this.item);
        },
        mustByRender(){
            let test = this.$store.state.itemI;
            let test2 = this.$store.state.showItemPrice(this.item);
        },
        showStock(){
            //console.log('Stock Option', this.stock);
            if(this.stock)
                return this.stock;
            else
                return this.$store.getters.getShowStock;
        },
        getContainerClass(){
            return {'card-large':this.showStock}
        }
    },
    methods: {
        openItemView:function(item){
            if(item)
                this.$router.push(`/view/${item.Code}`);
        },
        ItemComponentAddToCar:function(){
            if(isNaN(this.Qty) || this.Qty==0) {
                this.Qty = 0;
                return;
            }
            let self=this;
            this.$store.dispatch('ItemComponentAddToCar',{id:this.item.Code,cant:this.Qty}).then(()=>{
                self.$store.dispatch('saveCarSession');
            });
            this.$store.dispatch('updateCarAmount');
        },
        ItemComponentAddToCarOld:function(){
            if(isNaN(this.Qty) || this.Qty==0) {
                this.Qty = 0;
                return;
            }
            if(this.item.carCant)
                this.item.carCant=parseInt(this.item.carCant) +parseInt(this.Qty);
            else
                this.item.carCant=parseInt(this.Qty);
            this.$store.dispatch('ItemComponentAddToCar',this.item);
        },
        itemQtyIncrement:function(){
            if(Number.isInteger(this.Qty))
                this.Qty += 1;
            else
                this.Qty=1;

        },
        itemQtyDecrement:function(){
            if(this.Qty)
                this.Qty -= 1;
            else
                this.Qty=0;
        },
        toggleFavorite(itemCode){
            this.$store.dispatch('toggleFavourite',itemCode);
            Vue.nextTick(() => {
                this.favorite = !this.favorite;
            })
            //console.log('Item',this.favorite);
        },

    },
    template:`<div class="card" v-bind:class="getContainerClass">
                <span v-if="item.SpecialPrice"class="destaque2"><img src="../static/ecommerce/assets/img/especial.png" width="45"></span>
                <span v-if="item.Ecofriendly" class="destaque1"><img src="../static/ecommerce/assets/img/eco.png" width="45" alt=""> </span>
                <router-link :to="'/view/'+encodeToURL(item.Code)">
                    <div class="card-image" >
                        <itemSliderComponent :key="'slide'+item.Code" :item="item" :stockFlag="false" :container="false" v-if="$store.getters.getSetting && $store.getters.getSetting.DemoPhotos"></itemSliderComponent>
                    </div>
                </router-link>
                <div class="card-content">
                  <h3>
                    <router-link :to="'/view/'+item.Code">
                      <p>
                        <template v-if="item.CrossedOutPrice">
                           <strong class="crossedoutprice"  :title="tr('After')">$ {{formatFloat(item.CrossedOutPrice)}}</strong> <br>
                        </template>
                        <strong class="pricered" :title="tr('Now')" >$ {{currentItemPrice}}</strong>
                      </p>
                      <span v-if="showStock">Stock. {{item.Qty}}</span>
                      <span :class="{'item-name':!item.CrossedOutPrice , 'item-name-small':item.CrossedOutPrice}">{{item.Name}}</span>
                      <span>Empaque: {{item.Unit}}</span>
                      <span>Cod. {{item.Code}}</span>
                      <span>Art. {{item.AlternativeCode}}</span>
                    </router-link>
                  </h3>
                  <div class="item-action-buttons">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                          <tr>
                              <td width="20" height="29" class="center-align" @click.stop="itemQtyIncrement"><i class="fas fa-angle-up waves-effect"></i></td>
                              <td><input :ref="'carCant-'+item.Code" v-model="Qty" class="inputch" type="text"></td>
                              <td width="20" class="center-align" @click.stop="itemQtyDecrement"><i class="fas fa-angle-down waves-effect"></i></td>
                              <td width="20" class="center-align" @click.stop="ItemComponentAddToCar(item)"><i class="fas fa-cart-plus" style="color:#40a626" ></i></td>
                              <td width="20" class="center-align" v-if="$store.getters.getSession">
                                <span @click="toggleFavorite(item.Code)" >
                                    <template v-if="favorite">
                                        <icon name="heart"  color="red" :title="tr('Remove From Favorite')"></icon>
                                    </template>
                                    <template v-else>
                                        <icon name="regular/heart" color="green" :title="tr('Add To Favorite')"></icon>
                                    </template>                      
                                </span>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
              </div>
            </div>`
});